/**
 * Created by Lj on 2018/3/20.
 */
.bgimg {
  width: 100%; }

.title {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 55px;
  height: auto;
  overflow-y: hidden; }

.content_img img {
  width: auto;
  max-width: 96%;
  padding: 0 2%; }

.main2 .mainContent, .main3 .mainContent, .main4 .mainContent, .main5 .mainContent, .main6 .mainContent, .main7 .mainContent, .main8 .mainContent, .main9 .mainContent, .main10 .mainContent, .header .mainContent, .footer .mainContent {
  /*
    width: $widthMid;
    */
  margin-left: auto;
  margin-right: auto;
  width: 930px; }

.overX {
  overflow-x: hidden; }
  .overX .bgimg {
    width: 1600px; }

.header .hasHead {
  padding: 48.5px 0; }

.header .headContent {
  position: fixed;
  background: #ffffff;
  /*
    opacity: 0.92;
    */
  z-index: 1000;
  top: 0px;
  left: 0px;
  width: 100%; }

.header .menu {
  font-size: 14px;
  width: 58.125%;
  padding-left: 20.9375%;
  padding-right: 20.9375%;
  color: #353535;
  text-align: left; }
  .header .menu img {
    width: 100%; }
  .header .menu .logo {
    display: inline-block;
    width: 17.203%; }
  .header .menu .more {
    display: none; }
  .header .menu .menu_title {
    display: none; }
  .header .menu .menu_list {
    display: inline-block;
    min-width: 46.73%;
    text-align: center; }
    .header .menu .menu_list .flexViewC li a {
      line-height: 95px;
      width: 100%;
      display: inline-block;
      border-bottom: 2px solid #ffffff; }
    .header .menu .menu_list .flexViewC li a:hover, .header .menu .menu_list .flexViewC li a:active {
      color: #42ABE1;
      border-bottom: 2px solid #42ABE1; }
    .header .menu .menu_list .proNavsOn {
      color: #42ABE1;
      border-bottom: 2px solid #42ABE1 !important; }
    .header .menu .menu_list .proNavs {
      position: absolute;
      right: 10.46875%;
      background: #ffffff;
      /*        border-radius: 0.1 * 100px;
                box-shadow: 0.01 * 100px 0.01 * 100px 0.06 * 100px #f5f5f5;*/
      font-size: 12px; }
      .header .menu .menu_list .proNavs li {
        margin: 0 14px;
        padding: 21px 0 27px; }
        .header .menu .menu_list .proNavs li img {
          width: 78px; }
        .header .menu .menu_list .proNavs li a {
          /*
            padding: 0.275 * 100px 0;
            */
          line-height: 20px !important;
          width: 100%;
          color: #474747;
          display: inline-block;
          border-bottom: none !important; }
        .header .menu .menu_list .proNavs li a:hover, .header .menu .menu_list .proNavs li a:active {
          color: #42ABE1;
          border-bottom: none !important; }

.footer {
  color: #353535; }
  .footer .footer_info {
    padding-top: 76px;
    padding-bottom: 104px;
    background: #f3f3f3;
    flex-wrap: nowrap; }
    .footer .footer_info .logoFoot {
      text-align: left; }
      .footer .footer_info .logoFoot img {
        width: 184px; }
    .footer .footer_info .wxgzh {
      color: #5c5a5d;
      font-size: 12px; }
      .footer .footer_info .wxgzh img {
        width: 79px; }
      .footer .footer_info .wxgzh p {
        padding-top: 9px; }
    .footer .footer_info .lianxi {
      text-align: right; }
      .footer .footer_info .lianxi img {
        width: 250px; }
  .footer .footer_copyright {
    background: #5c5a5d;
    font-size: 12px;
    color: #ffffff;
    padding: 33px 0; }

.index .indexmain3 {
  overflow: hidden; }
  .index .indexmain3 .title img {
    width: 645px; }
  .index .indexmain3 .product_index {
    position: relative;
    min-height: 236px;
    overflow: hidden; }
  .index .indexmain3 .content {
    margin: 32px 0 35px;
    height: 286px; }
    .index .indexmain3 .content li:hover, .index .indexmain3 .content li:active {
      border: #259CDA 3px solid;
      color: #259CDA; }
      .index .indexmain3 .content li:hover p, .index .indexmain3 .content li:active p {
        color: #99DAFC; }
    .index .indexmain3 .content li {
      cursor: pointer;
      transition: all .2s ease-in;
      border: #ffffff 3px solid;
      background: #ffffff;
      width: 162px;
      height: 206px;
      float: left;
      padding: 37px 24px;
      margin: 0 1.172%;
      color: #474747;
      text-align: center;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center; }
      .index .indexmain3 .content li img {
        display: block;
        margin: 0 auto 17px; }
      .index .indexmain3 .content li p {
        display: block;
        color: #ABABAB;
        margin-top: 14px; }

.index .indexmain4 {
  position: relative; }
  .index .indexmain4 .title {
    position: absolute;
    left: 0;
    top: 0;
    text-align: center; }
    .index .indexmain4 .title img {
      width: 459px; }
  .index .indexmain4 .content {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0.84rem; }
    .index .indexmain4 .content a {
      display: inline-block;
      width: 63px;
      height: 63px;
      border-radius: 100%;
      font-size: 14px;
      margin: 0 23.5px;
      background: #42ABE1;
      color: #ffffff;
      transition: all .2s ease-in; }
      .index .indexmain4 .content a p {
        height: 40px;
        margin-top: 10px; }
    .index .indexmain4 .content a:hover, .index .indexmain4 .content a:active {
      background: #ffffff;
      color: #42ABE1; }

.about .main2 .title img {
  width: 156px; }

.about .main2 .content {
  padding-bottom: 58px;
  padding-top: 22px; }

.about .main2 p {
  color: #838383;
  font-size: 12px;
  margin-bottom: 14px;
  line-height: 20px; }

.about .main3 .title .content_title {
  display: block;
  margin: 35px auto 0; }

.about .main3 .content {
  padding-top: 92px;
  padding-bottom: 148px;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-around; }
  .about .main3 .content li {
    width: 0;
    flex-grow: 1;
    flex-shrink: 0;
    text-align: left;
    padding: 31px 1% 59px;
    margin: 0 0.5%;
    color: #626262;
    max-width: 159px; }
    .about .main3 .content li p:first-child {
      color: #464646; }
    .about .main3 .content li p:nth-child(2) {
      margin-bottom: 41px;
      margin-top: 16px;
      color: #000000; }

.about .main4 {
  overflow: hidden; }
  .about .main4 .title img {
    width: 338px; }
  .about .main4 .rollBox {
    width: 100%;
    position: relative;
    margin-top: 19px;
    margin-bottom: 34px;
    overflow: hidden; }
    .about .main4 .rollBox .lunbo {
      max-height: 296px;
      overflow: hidden; }
      .about .main4 .rollBox .lunbo .pic {
        margin: 35px 28px;
        width: 207px;
        height: 207px;
        float: left;
        border-radius: 100%;
        border: #efeef3 2px solid;
        overflow: hidden; }
        .about .main4 .rollBox .lunbo .pic img {
          width: 100%; }
      .about .main4 .rollBox .lunbo .pic:active, .about .main4 .rollBox .lunbo .pic:hover {
        margin: 0 28px;
        width: 274px;
        height: 274px; }

.about .main5 {
  text-align: center;
  color: #4E525F;
  font-size: 12px;
  padding-bottom: 60px; }
  .about .main5 .title img {
    width: 115px; }
  .about .main5 .flexViewB div {
    padding: 0 1%;
    width: 98%;
    flex-grow: 1; }
  .about .main5 .flexViewB img {
    width: 34px;
    margin: 42px 0 24px; }

.join {
  font-size: 12px; }
  .join .main2 .title img {
    width: 495px; }
  .join .main2 .content {
    padding-top: 55px;
    padding-bottom: 54px; }
    .join .main2 .content p {
      color: #464646;
      margin-top: 17px; }
    .join .main2 .content li {
      flex-grow: 1;
      width: 0; }
      .join .main2 .content li div {
        background: url(../images/zhaopin-2-1-2.png) center center no-repeat;
        width: 60px;
        background-size: 100%;
        margin: 0 auto;
        transition: all .2s ease-in; }
        .join .main2 .content li div img {
          opacity: 0; }
    .join .main2 .content li:hover div, .join .main2 .content li:active div, .join .main2 .content .checked div {
      background: url(../images/zhaopin-2-1-1.png) center center no-repeat;
      width: 60px;
      background-size: 100%; }
  .join .main3 .menu_title ul {
    background: #C3CAD7;
    border-radius: 5px;
    padding: 15px 22px; }
    .join .main3 .menu_title ul li {
      width: 25%;
      color: #FFFFFF; }
  .join .main3 .current ul {
    border-radius: 5px 5px 0 0 !important; }
  .join .main3 .content {
    margin-top: 6px;
    margin-bottom: 6px;
    overflow: hidden; }
    .join .main3 .content ul {
      text-align: center;
      background: #F5F7FA;
      border-radius: 5px;
      padding: 15px 22px; }
      .join .main3 .content ul li {
        color: #464646;
        width: 25%; }
    .join .main3 .content ul:active, .join .main3 .content ul:hover {
      background: #e9eef5; }
    .join .main3 .content .content_body {
      display: none;
      border-radius: 0 0 5px 5px;
      color: #464646;
      line-height: 18px;
      text-align: left;
      padding: 16px 4%;
      border-left: 1px solid #EEEEEE;
      border-bottom: 1px solid #EEEEEE;
      border-right: 1px solid #EEEEEE; }
      .join .main3 .content .content_body h1 {
        color: #464646;
        font-size: 12px; }
      .join .main3 .content .content_body div {
        margin: 23px auto 7px;
        text-align: center; }
      .join .main3 .content .content_body .join_btn {
        width: 121px; }

@-webkit-keyframes rotateY {
  50% {
    transform: rotateY(360deg);
    -ms-transform: rotateY(360deg);
    -moz-transform: rotateY(360deg);
    -webkit-transform: rotateY(360deg);
    -o-transform: rotateY(360deg); } }

.product .main2 .imgB {
  display: flex; }

.product .main2 .imgS {
  display: none; }

.product .main2 .content {
  padding-top: 59px;
  padding-bottom: 51px;
  /*      display: flex;
            flex-wrap: nowrap;
            align-items: stretch;
            flex-direction: row;
            justify-content: space-around;*/
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  text-align: left; }
  .product .main2 .content .hastop {
    margin-top: 28px; }
  .product .main2 .content .product_img {
    width: 42%;
    text-align: right; }
    .product .main2 .content .product_img img {
      width: 350px; }
  .product .main2 .content .product_body {
    font-size: 14px;
    color: #474747;
    width: 51%;
    margin-left: 100px;
    max-width: 327px; }
    .product .main2 .content .product_body span {
      display: block;
      margin-bottom: 32px; }
    .product .main2 .content .product_body p {
      font-size: 12px;
      color: #767676;
      line-height: 20px; }
  .product .main2 .content .flexViewB {
    margin-top: 30px; }
    .product .main2 .content .flexViewB img {
      width: 70px; }
  .product .main2 .content .bgGrey {
    width: 0;
    flex-grow: 1;
    flex-shrink: 0;
    text-align: left;
    padding: 31px 2.4% 59px; }

.product .main3 {
  padding-bottom: 59px; }

.product .product_infoS {
  display: none; }

.product .product_infoB {
  display: table; }

.product .product_info {
  border-collapse: collapse;
  padding: 0 0 88px;
  margin: 0 auto;
  width: 100%; }
  .product .product_info tr {
    text-align: center; }
  .product .product_info td:first-child {
    width: 133px; }
  .product .product_info td {
    padding: 10px 50px;
    border: 1px solid #f5f5f5; }
  .product .product_info thead {
    background: #79c1e6;
    color: #ffffff;
    font-size: 16px; }
  .product .product_info tbody {
    font-size: 12px;
    color: #555555;
    background: #ffffff; }
    .product .product_info tbody tr:nth-child(2n) {
      background: #e2eff5; }

.product .upgrade {
  overflow: hidden;
  padding: 37px 0 42px; }
  .product .upgrade ul {
    color: #555555;
    font-size: 12px; }
    .product .upgrade ul .upgrade_content {
      float: left;
      padding: 33px 0;
      cursor: pointer;
      transition: all .2s ease-in;
      border: 3px solid #fff;
      background: #fff;
      width: 194px;
      height: 222px;
      margin: 12.5px 0.85%;
      font-size: 12px;
      color: #474747;
      /*
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        */ }
      .product .upgrade ul .upgrade_content img {
        width: 74px; }
      .product .upgrade ul .upgrade_content span {
        display: block;
        color: #259CDA;
        font-size: 16px;
        margin: 27px 0px; }
      .product .upgrade ul .upgrade_content p {
        line-height: 20px; }
    .product .upgrade ul .upgrade_content:active, .product .upgrade ul .upgrade_content:hover {
      margin: 0 0.85%;
      padding: 65px 15px 51px;
      background: #259CDA;
      color: #ffffff;
      border: 3px solid #259CDA; }
      .product .upgrade ul .upgrade_content:active span, .product .upgrade ul .upgrade_content:hover span {
        color: #ffffff; }

.product .scene {
  padding: 43px 1% 48px; }
  .product .scene li {
    margin: 0 0.5%; }
    .product .scene li img {
      display: block;
      width: 100%; }
    .product .scene li span {
      width: 100%;
      background: #f5f5f5;
      font-size: 14px;
      color: #474747;
      display: inline-block;
      line-height: 60px; }
  .product .scene li:hover span, .product .scene li:active span {
    background: #e9f5fb; }

.locationmonitor .main2 .title img {
  width: 744px; }

.locationmonitor .main3 .title img {
  width: 830px; }

.sharepay .main2 .title img {
  width: 553px; }

.sharepay .main3 .title img {
  width: 232px; }

.sharepay .main4 .title img {
  width: 312px; }

.shareCabinet .main2 .title img {
  width: 554px; }

.shareCabinet .main3 .title img {
  width: 382px; }

.shareLock .main2 .title img {
  width: 575px; }

.shareLock .main3 .title img {
  width: 125px; }

.shareLock .main4 .title img {
  width: 295px; }

.sharedock .main2 .title img {
  width: 492.5px; }

.sharedock .main3 .title img {
  width: 118.5px; }

.sharedock .main7 .title img {
  width: 118.5px; }

.sharedock .main7 .content {
  padding-top: 59px;
  padding-bottom: 51px; }

.sharedock .main4 .title img {
  width: 232px; }

.sharedock .indexmain5 {
  position: relative; }
  .sharedock .indexmain5 .title {
    position: absolute;
    left: 0;
    top: 0;
    text-align: center; }
    .sharedock .indexmain5 .title img {
      width: 118px; }

.sharedock .indexmain6 {
  position: relative; }
  .sharedock .indexmain6 .main6 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%; }
    .sharedock .indexmain6 .main6 .title {
      text-align: center; }
      .sharedock .indexmain6 .main6 .title img {
        width: 115.5px; }
    .sharedock .indexmain6 .main6 .content {
      padding-bottom: 81px;
      padding-top: 87px;
      text-align: center;
      color: #ffffff;
      font-size: 12px; }
      .sharedock .indexmain6 .main6 .content img {
        width: 87px;
        height: 87px; }
    .sharedock .indexmain6 .main6 .div_content {
      background: #5242D1;
      border-radius: 10px;
      padding: 31px;
      width: 263px;
      height: 211px; }
      .sharedock .indexmain6 .main6 .div_content .div_title {
        font-size: 21px;
        padding-top: 13px;
        padding-bottom: 16px; }
      .sharedock .indexmain6 .main6 .div_content p {
        line-height: 20px; }
    .sharedock .indexmain6 .main6 .div_content:first-child {
      margin-right: 149px;
      background: #0084FF; }

.babyBurglar .main2 .title img {
  width: 304px; }

.babyBurglar .main6 .title {
  margin-top: 102 -55px; }
  .babyBurglar .main6 .title img {
    width: 303px; }

.babyBurglar .main6 .content {
  margin-top: 28px; }
  .babyBurglar .main6 .content img {
    width: 607px; }

.babyBurglar .main7 .title {
  margin-top: 29px; }
  .babyBurglar .main7 .title img {
    width: 303px; }

.babyBurglar .main7 .content {
  margin-top: 68px;
  padding-bottom: 116px; }
  .babyBurglar .main7 .content img {
    width: 429px; }

.babyBurglar .main5 .title img {
  width: 180px; }

.babyBurglar .main5 .content {
  padding-top: 59px;
  padding-bottom: 51px;
  /*      display: flex;
            flex-wrap: nowrap;
            align-items: stretch;
            flex-direction: row;
            justify-content: space-around;*/
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  text-align: left; }
  .babyBurglar .main5 .content .hastop {
    margin-top: 28px; }
  .babyBurglar .main5 .content .product_img {
    width: 432px;
    text-align: right; }
    .babyBurglar .main5 .content .product_img img {
      width: 350px; }
  .babyBurglar .main5 .content .product_body {
    font-size: 14px;
    color: #474747;
    width: 51%;
    margin-left: 67px;
    max-width: 386px; }
    .babyBurglar .main5 .content .product_body span {
      display: block;
      margin-bottom: 32px; }
    .babyBurglar .main5 .content .product_body p {
      font-size: 12px;
      color: #767676;
      line-height: 20px; }

.babyBurglar .indexmain4 {
  position: relative;
  background: url("../images/bj-ying-4.png") left top;
  background-size: 1600px 839px; }
  .babyBurglar .indexmain4 .main4 {
    /*
      position: absolute;
      left: 0;
      top: 0;
      */
    width: 100%; }
    .babyBurglar .indexmain4 .main4 .title {
      text-align: center; }
      .babyBurglar .indexmain4 .main4 .title img {
        width: 189px; }
    .babyBurglar .indexmain4 .main4 .mainContent {
      padding-bottom: 88px;
      padding-top: 36px; }

.sharewheelchair .main2 .title img {
  width: 241.1px; }

.sharewheelchair .main5 .title img {
  width: 118.5px; }

.sharewheelchair .main5 .content {
  margin: 49px 0 106px; }

.sharewheelchair .main5 .borderGreyDiv {
  font-size: 14px;
  color: #474747; }
  .sharewheelchair .main5 .borderGreyDiv div {
    border: 1px solid #E1E3E3;
    border-radius: 8px;
    width: 320px;
    height: 104px;
    padding: 30px 0;
    margin: 15.5px 26px; }
  .sharewheelchair .main5 .borderGreyDiv img {
    margin-bottom: 24px;
    width: 65px; }

.sharewheelchair .main7 .title img {
  width: 232px; }

.sharewheelchair .indexmain4 {
  position: relative; }
  .sharewheelchair .indexmain4 .title {
    position: absolute;
    left: 0;
    top: 0;
    text-align: center; }
    .sharewheelchair .indexmain4 .title img {
      width: 241.1px; }

.sharewheelchair .indexmain6 {
  position: relative; }
  .sharewheelchair .indexmain6 .main6 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%; }
    .sharewheelchair .indexmain6 .main6 .title {
      text-align: center; }
      .sharewheelchair .indexmain6 .main6 .title img {
        width: 118px; }
    .sharewheelchair .indexmain6 .main6 .mainContent {
      padding-bottom: 88px;
      padding-top: 66px; }

.escortbed .main2 .title img {
  width: 247.25px; }

.escortbed .indexmain4 {
  position: relative; }
  .escortbed .indexmain4 .title {
    position: absolute;
    left: 0;
    top: 0;
    text-align: center; }
    .escortbed .indexmain4 .title img {
      width: 273px; }

.escortbed .main5 .title img {
  width: 118.5px; }

.escortbed .main5 .content {
  margin: 77px 0 137px; }

.escortbed .main5 .borderGreyDiv {
  font-size: 12px;
  color: #6D6D6D; }
  .escortbed .main5 .borderGreyDiv div {
    border: 1px solid #E9EAEE;
    border-radius: 10px;
    width: 201px;
    height: 202px;
    padding: 48px 21px;
    margin: 0px 17px; }
  .escortbed .main5 .borderGreyDiv .content_title {
    color: #474747;
    font-size: 20px;
    padding: 28px 0 28px; }
  .escortbed .main5 .borderGreyDiv p {
    line-height: 20px; }
  .escortbed .main5 .borderGreyDiv img {
    width: 72px; }

.escortbed .indexmain6 {
  position: relative; }
  .escortbed .indexmain6 .main6 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%; }
    .escortbed .indexmain6 .main6 .title {
      text-align: center; }
      .escortbed .indexmain6 .main6 .title img {
        width: 118px; }
    .escortbed .indexmain6 .main6 .mainContent {
      padding-bottom: 88px;
      padding-top: 66px; }

.escortbed .main7 .title img {
  width: 118px; }

.escortbed .main7 .content {
  padding-top: 60px; }

.escortbed .main7 .flexViewC {
  background: #ffffff; }
  .escortbed .main7 .flexViewC .flex1 {
    width: 464px; }
    .escortbed .main7 .flexViewC .flex1 p {
      padding: 0 63px;
      color: #818181;
      font-size: 14px;
      margin-top: 41px; }
    .escortbed .main7 .flexViewC .flex1 span {
      padding: 0 63px;
      color: #222222;
      font-size: 20px; }

.escortbed .main8 .title img {
  width: 118px; }

.escortbed .main8 .content {
  padding-top: 66px;
  padding-bottom: 159px; }

.escortbed .main8 table {
  table-layout: fixed; }
  .escortbed .main8 table thead td:first-child {
    padding: 0;
    position: relative;
    background: linear-gradient(18deg, transparent 49.5%, #f5f5f5 49.5%, #f5f5f5 50.5%, transparent 50.5%); }
    .escortbed .main8 table thead td:first-child p {
      padding: 0px 6px; }

.indexmain9 {
  position: relative;
  background: url("../images/bj-chuang-8.png") left bottom no-repeat;
  background-size: 100%;
  background-color: #0E95EF; }
  .indexmain9 .main9 {
    /*
    position: absolute;
    left: 0;
    top: 0;
    */
    width: 100%; }
    .indexmain9 .main9 .title {
      text-align: center; }
      .indexmain9 .main9 .title img {
        width: 118px; }
    .indexmain9 .main9 .content {
      width: 925px;
      margin: 0 auto;
      /*
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      */ }
    .indexmain9 .main9 .content_body {
      color: #FFFFFF;
      font-size: 12px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: stretch; }
      .indexmain9 .main9 .content_body img {
        width: 49px;
        padding-right: 34px; }
      .indexmain9 .main9 .content_body .div_title {
        font-size: 18px;
        padding-bottom: 30px; }
      .indexmain9 .main9 .content_body .div_content {
        text-align: left;
        width: 50%;
        padding-left: 89px;
        padding-top: 47px;
        padding-bottom: 0px;
        padding-right: 46px;
        margin-bottom: 81px;
        margin-top: 0;
        position: relative; }
        .indexmain9 .main9 .content_body .div_content p {
          width: 276px; }
    .indexmain9 .main9 .content_body:first-child {
      border-bottom: 1px solid rgba(255, 255, 255, 0.49); }
      .indexmain9 .main9 .content_body:first-child .div_content {
        padding-top: 0px;
        padding-bottom: 81px;
        margin-top: 47px;
        margin-bottom: 0; }
      .indexmain9 .main9 .content_body:first-child .div_content:first-child:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: 1px;
        height: 100%;
        background: rgba(255, 255, 255, 0.49); }

.main10 .title img {
  width: 118px; }

.main10 .flexViewC {
  padding-top: 47px;
  padding-bottom: 51px; }
  .main10 .flexViewC .flex1 {
    margin: 0 4px; }

.blueBjContent {
  flex-wrap: wrap;
  text-align: center;
  font-size: 12px;
  color: #666666; }
  .blueBjContent img {
    width: 47px;
    height: 47px; }
  .blueBjContent .div_content {
    background: #ffffff;
    border-radius: 6px;
    text-align: left;
    padding: 32px 20px;
    width: 240px;
    height: 216px;
    margin: 0 43px 11px 0; }
    .blueBjContent .div_content .div_title {
      font-size: 22px;
      padding-top: 26px;
      padding-bottom: 26px;
      color: #222222; }
    .blueBjContent .div_content p {
      line-height: 20px; }
  .blueBjContent .div_content:nth-child(3n) {
    margin: 0px 0px 11px; }
  .blueBjContent .div_content:hover {
    background: #1A6EFF;
    color: #ffffff; }
    .blueBjContent .div_content:hover .div_title {
      color: #ffffff; }

.solution {
  position: absolute;
  left: 0;
  bottom: 111px;
  text-align: center;
  width: 100%; }
  .solution img {
    width: 648px; }
