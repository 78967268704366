/**
 * Created by Lj on 2018/3/20.
 */

$widthBig: 1136px; // 59.2% 1136px;
$widthPad: 0;

$widthMid: 58.125%;
$widthPadM: 20.9375%;

$widthSmall: 96%;
$widthPadS: 2%;
$baseFont: 0.16px;

.bgimg {
  width: 100%;
}

.title {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 0.55 * 100px;
  height: auto;
  overflow-y: hidden;
}

.content_img {
  img {
    width: auto;
    max-width: 96%;
    padding: 0 2%;
  }
}

.main2, .main3, .main4, .main5, .main6, .main7, .main8, .main9, .main10, .header, .footer {
  .mainContent {
    /*
    width: $widthMid;
    */
    margin-left: auto;
    margin-right: auto;
    width: 930px;
  }
}

.overX {
  overflow-x: hidden;
  .bgimg {
    width: 1600px;
  }
}

// header
.header {
  .hasHead {
    padding: 0.485 * 100px 0;
  }
  .headContent {
    position: fixed;
    background: #ffffff;
    /*
    opacity: 0.92;
    */
    z-index: 1000;
    top: 0px;
    left: 0px;
    width: 100%;
  }
  .menu {
    font-size: 14px;
    width: $widthMid;
    padding-left: $widthPadM;
    padding-right: $widthPadM;
    img {
      width: 100%;
    }
    // font-size: 24px;
    color: #353535;
    text-align: left;
    .logo {
      display: inline-block;
      width: 17.203%;
    }
    .more {
      display: none;
    }
    .menu_title {
      display: none;
    }
    .menu_list {
      display: inline-block;
      min-width: 46.73%;
      text-align: center;
      .flexViewC {
        li {
          a {
            line-height: 0.95 * 100px;
            width: 100%;
            display: inline-block;
            border-bottom: 0.02 * 100px solid #ffffff;
          }
          a:hover, a:active {
            color: #42ABE1;
            border-bottom: 0.02 * 100px solid #42ABE1;
          }
        }
      }
      .proNavsOn {
        color: #42ABE1;
        border-bottom: 0.02 * 100px solid #42ABE1 !important;
      }
      .proNavs {
        position: absolute;
        right: $widthPadM/2;
        background: #ffffff;
        /*        border-radius: 0.1 * 100px;
                box-shadow: 0.01 * 100px 0.01 * 100px 0.06 * 100px #f5f5f5;*/
        font-size: 12px;
        li {
          margin: 0 0.14 * 100px;
          padding: 0.21 * 100px 0 0.27 * 100px;
          img {
            width: 0.78 * 100px;
          }
          a {
            /*
            padding: 0.275 * 100px 0;
            */
            // font-size: 18px;
            line-height: .20 * 100px !important;
            width: 100%;
            color: #474747;
            display: inline-block;
            border-bottom: none !important;
          }
          a:hover, a:active {
            color: #42ABE1;
            border-bottom: none !important;
          }
        }
      }
    }
  }
}

// footer
.footer {
  color: #353535;
  .footer_info {
    padding-top: 0.76 * 100px;
    padding-bottom: 1.04 * 100px;
    background: #f3f3f3;
    flex-wrap: nowrap;
    .logoFoot {
      text-align: left;
      img {
        width: 184px;
      }
    }
    .wxgzh {
      color: #5c5a5d;
      font-size: 12px;
      img {
        width: 0.79 * 100px;
      }
      p {
        padding-top: 0.09 * 100px;
      }
    }
    .lianxi {
      img {
        width: 2.5 * 100px;
      }
      text-align: right;
    }
  }
  .footer_copyright {
    background: #5c5a5d;
    font-size: 12px;
    color: #ffffff;
    padding: 0.33 * 100px 0;
  }
}

// index

.index {
  .indexmain3 {
    overflow: hidden;
    .title {
      img{
        width: 6.45 * 100px;
      }
    }
    .product_index {
      position: relative;
      min-height: 2.36 * 100px;
      overflow: hidden;
    }
    .content {
      margin: .32 * 100px 0 0.35 * 100px;
      height: 2.86 * 100px;
      li:hover, li:active {
        border: #259CDA 0.03 * 100px solid;
        color: #259CDA;
        p {
          color: #99DAFC;
        }
      }
      li {
        cursor: pointer;
        transition: all .2s ease-in;
        border: #ffffff 0.03 * 100px solid;
        background: #ffffff;
        width: 1.62 * 100px;
        height: 2.06 * 100px;
        float: left;
        padding: .37 * 100px 0.24 * 100px;
        margin: 0 1.172%;
        color: #474747;
        text-align: center;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        img {
          display: block;
          margin: 0 auto 0.17 * 100px;
        }
        p {
          display: block;
          color: #ABABAB;
          margin-top: 0.14 * 100px;

        }
      }
    }
  }

  .indexmain4 {
    position: relative;
    .title {
      position: absolute;
      left: 0;
      top: 0;
      text-align: center;
      img {
        width: 4.59 * 100px;
      }
    }

    .content {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0.84rem;
      a {
        display: inline-block;
        width: 0.63 * 100px;
        height: 0.63 * 100px;
        // line-height: 0.63 * 100px;
        border-radius: 100%;
        font-size: 14px;
        margin: 0 0.235 * 100px;
        background: #42ABE1;
        color: #ffffff;
        transition: all .2s ease-in;
        p {
          height: 40px;
          margin-top: 10px;
        }
      }
      a:hover, a:active {
        background: #ffffff;
        color: #42ABE1;
      }
    }
  }
}

.about {
  .main2 {
    .title {
      img {
        width: 1.56 * 100px
      }
    }
    .content {
      padding-bottom: 0.58 * 100px;
      padding-top: 0.22 * 100px;
    }
    p {
      color: #838383;
      font-size: 12px;
      margin-bottom: 0.14 * 100px;
      line-height: 0.2 * 100px;
    }
  }
  .main3 {
    .title .content_title {
      display: block;
      margin: 0.35 * 100px auto 0;
    }
    .content {
      padding-top: 0.92 * 100px;
      padding-bottom: 1.48 * 100px;
      display: flex;
      flex-wrap: nowrap;
      align-items: stretch;
      flex-direction: row;
      justify-content: space-around;
      li {
        width: 0;
        flex-grow: 1;
        flex-shrink: 0;
        text-align: left;
        padding: 0.31 * 100px 1% 0.59 * 100px;
        margin: 0 0.5%;
        // font-size: 14px;
        color: #626262;
        max-width: 159px;
        p:first-child {
          // font-size: 24px;
          color: #464646;
        }
        p:nth-child(2) {
          margin-bottom: 0.41 * 100px;
          margin-top: 0.16 * 100px;
          // font-size: 12px;
          color: #000000;
        }
      }
    }
  }
  .main4 {
    overflow: hidden;
    .title {
      img {
        width: 3.38 * 100px
      }
    }
    .rollBox {
      width: 100%;
      position: relative;
      margin-top: 0.19 * 100px;
      margin-bottom: 0.34 * 100px;
      overflow: hidden;
      .lunbo {
        max-height: 2.96 * 100px;
        overflow: hidden;
        .pic {
          margin: 0.35 * 100px 28px;
          width: 2.07 * 100px;
          height: 2.07 * 100px;
          float: left;
          border-radius: 100%;
          border: #efeef3 0.02 * 100px solid;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
        .pic:active, .pic:hover {
          margin: 0 28px;
          width: 2.74 * 100px;
          height: 2.74 * 100px;
        }
      }
    }
  }
  .main5 {
    text-align: center;
    color: #4E525F;
    font-size: 12px;
    padding-bottom: 0.60 * 100px;
    .title {
      img {
        width: 1.15 * 100px
      }
    }
    .flexViewB {
      div {
        padding: 0 1%;
        width: 98%;
        flex-grow: 1;
      }
      img {
        width: 0.34 * 100px;
        margin: 0.42 * 100px 0 0.24 * 100px;
      }
    }
  }
}

.join {
  font-size: 12px;
  .main2 {
    .title {
      img {
        width: 4.95 * 100px;
      }
    }
    .content {
      padding-top: 0.55 * 100px;
      padding-bottom: 0.54 * 100px;
      p {
        color: #464646;
        margin-top: 0.17 * 100px;
      }
      li {
        flex-grow: 1;
        width: 0;
        div {
          background: url(../images/zhaopin-2-1-2.png) center center no-repeat;
          width: 0.6 * 100px;
          background-size: 100%;
          margin: 0 auto;
          img {
            opacity: 0;
          }
          transition: all .2s ease-in;

        }
      }
      li:hover, li:active, .checked {
        div {
          background: url(../images/zhaopin-2-1-1.png) center center no-repeat;
          width: 0.60 * 100px;
          background-size: 100%;
        }
      }
    }
  }
  .main3 {
    .menu_title ul {
      background: #C3CAD7;
      border-radius: 0.05 * 100px;
      padding: 0.15 * 100px 0.22 * 100px;
      li {
        width: 25%;
        color: #FFFFFF;
        // font-size: 14px;
      }
    }
    .current {
      ul {
        border-radius: 0.05 * 100px 0.05 * 100px 0 0 !important;
      }
    }
    .content {
      margin-top: 0.06 * 100px;
      margin-bottom: 0.06 * 100px;
      overflow: hidden;
      ul {
        text-align: center;
        background: #F5F7FA;
        border-radius: 0.05 * 100px;
        padding: 0.15 * 100px 0.22 * 100px;
        li {
          // font-size: 14px;
          color: #464646;
          width: 25%;
        }
      }
      ul:active, ul:hover {
        background: #e9eef5;
      }
      .content_body {
        display: none;
        border-radius: 0 0 0.05 * 100px 0.05 * 100px;
        h1 {
          color: #464646;
          font-size: 12px;
        }
        color: #464646;
        // font-size: 12px;
        line-height: 0.18 * 100px;
        text-align: left;
        padding: 0.16 * 100px 4%;
        border-left: 0.01 * 100px solid #EEEEEE;
        border-bottom: 0.01 * 100px solid #EEEEEE;
        border-right: 0.01 * 100px solid #EEEEEE;
        div {
          margin: 0.23 * 100px auto 0.07 * 100px;
          text-align: center;
        }
        .join_btn {
          width: 1.21 * 100px;
        }
      }
    }
  }
}

@-webkit-keyframes rotateY {
  50% {
    transform: rotateY(360deg);
    -ms-transform: rotateY(360deg);
    -moz-transform: rotateY(360deg);
    -webkit-transform: rotateY(360deg);
    -o-transform: rotateY(360deg);
  }

}



.product {
  .main2 {
    .imgB {
      display: flex;
    }
    .imgS {
      display: none;
    }
    .content {
      padding-top: 0.59 * 100px;
      padding-bottom: 0.51 * 100px;
      /*      display: flex;
            flex-wrap: nowrap;
            align-items: stretch;
            flex-direction: row;
            justify-content: space-around;*/
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      text-align: left;
      .hastop {
        margin-top: 0.28 * 100px;
      }
      .product_img {
        width: 42%;
        text-align: right;
        img {
          width: 3.5 * 100px;
        }
      }
      .product_body {
        font-size: 14px;
        color: #474747;
        width: 51%;
        margin-left: 1 * 100px;
        max-width: 327px;
        span {
          display: block;
          margin-bottom: 0.32 * 100px;
        }
        p {
          font-size: 12px;
          color: #767676;
          line-height: 20px;
        }
      }
      .flexViewB {
        margin-top: 0.30 * 100px;
        img {
          // width: 0.50 * 100px;
          width: 0.7 * 100px;
        }
      }

      .bgGrey {
        width: 0;
        flex-grow: 1;
        flex-shrink: 0;
        text-align: left;
        padding: 0.31 * 100px 2.4% 0.59 * 100px;
      }
    }
  }
  .main3 {
    padding-bottom: 0.59 * 100px;
  }
  .product_infoS {
    display: none;
  }
  .product_infoB {
    display: table;
  }
  .product_info {
    border-collapse: collapse;
    padding: 0 0 0.88 * 100px;
    margin: 0 auto;
    width: 100%;
    tr {

      text-align: center;
    }
    td:first-child {
      width: 1.33 * 100px;
    }
    td {
      padding: .1 * 100px .5 * 100px;
      border: 0.01 * 100px solid #f5f5f5;
    }
    thead {
      background: #79c1e6;
      color: #ffffff;
      font-size: 16px;
    }
    tbody {
      font-size: 12px;
      color: #555555;
      background: #ffffff;
      tr:nth-child(2n) {
        background: #e2eff5;
      }
    }
  }
  .upgrade {
    overflow: hidden;
    padding: .37 * 100px 0 .42 * 100px;
    ul {
      color: #555555;
      font-size: 12px;
      .upgrade_content {
        float: left;
        padding: 0.33 * 100px 0;
        // max-width: 204px;
        cursor: pointer;
        transition: all .2s ease-in;
        border: 0.03 * 100px solid #fff;
        background: #fff;
        width: 194px;
        height: 222px;
        margin: 0.125 * 100px 0.85%;
        font-size: 12px;
        color: #474747;
        /*
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        */
        img {
          width: 0.74 * 100px;
        }
        span {
          display: block;
          color: #259CDA;
          font-size: 16px;
          margin: 0.27 * 100px 0px;
        }
        p {
          line-height: 0.2 * 100px;
        }
      }
      .upgrade_content:active, .upgrade_content:hover {
        margin: 0 0.85%;
        padding: 0.65 * 100px 0.15 * 100px 0.51 * 100px;
        background: #259CDA;
        color: #ffffff;
        border: 0.03 * 100px solid #259CDA;
        span {
          color: #ffffff;
        }
      }
    }
  }
  .scene {
    padding: 0.43 * 100px 1% 0.48 * 100px;
    li {
      margin: 0 0.5%;
      img {
        display: block;
        width: 100%;
      }
      span {
        width: 100%;
        background: #f5f5f5;
        font-size: 14px;
        color: #474747;
        display: inline-block;
        line-height: 0.6 * 100px;
      }
    }
    li:hover, li:active {
      span {
        background: #e9f5fb;
      }
    }
  }
}

.locationmonitor {
  .main2 {
    .title {
      img {
        width: 7.44 * 100px;
      }
    }
  }
  .main3 {
    .title {
      img {
        width: 8.3 * 100px
      }
    }
  }
}

.sharepay {
  .main2 {
    .title {
      img {
        width: 5.53 * 100px;
      }
    }
  }
  .main3 {
    .title {
      img {
        width: 2.32 * 100px;
      }
    }
  }
  .main4 {
    .title {
      img {
        width: 3.12 * 100px;
      }
    }
  }
}

.shareCabinet {
  .main2 {
    .title {
      img {
        width: 5.54 * 100px;
      }
    }
  }
  .main3 {
    .title {
      img {
        width: 3.82 * 100px;
      }
    }
  }
}

.shareLock {
  .main2 {
    .title {
      img {
        width: 5.75 * 100px;
      }
    }
  }
  .main3 {
    .title {
      img {
        width: 1.25 * 100px;
      }
    }
  }
  .main4 {
    .title {
      img {
        width: 2.95 * 100px;
      }
    }
  }
}

.sharedock {
  .main2 {
    .title {
      img {
        width: 4.925 * 100px;
      }
    }
  }
  .main3 {
    .title {
      img {
        width: 118.5px;
      }
    }
  }
  .main7 {
    .title {
      img {
        width: 118.5px;;
      }
    }
    .content {
      padding-top: .59 * 100px;
      padding-bottom: .51 * 100px;
    }
  }
  .main4 {
    .title {
      img {
        width: 2.32 * 100px;
      }
    }
  }
  .indexmain5 {
    position: relative;
    .title {
      position: absolute;
      left: 0;
      top: 0;
      text-align: center;
      img {
        width: 1.18*100px;
      }
    }
  }
  .indexmain6 {
    position: relative;
    .main6 {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      .title {
        text-align: center;
        img {
          width: 115.5px;
        }
      }
      .content {
        img {
          width: 0.87 * 100px;
          height: 0.87 * 100px;
        }
        padding-bottom: 0.81 * 100px;
        padding-top: 0.87 * 100px;
        text-align: center;
        color: #ffffff;
        font-size: 12px;
      }
      .div_content {
        background: #5242D1;
        border-radius: 10px;
        padding: 0.31 * 100px;
        width: 2.63 * 100px;
        height: 2.11 * 100px;
        .div_title {
          font-size: 21px;
          padding-top: 13px;
          padding-bottom: 16px;
        }
        p {
          line-height: 20px;
        }
      }
      .div_content:first-child {
        margin-right: 1.49 * 100px;
        background: #0084FF;
      }
    }
  }
}

.babyBurglar {
  .main2 {
    .title {
      img {
        width: 3.04 * 100px;
      }
    }
  }
  .main6 {
    .title {
      margin-top: 102 -55px;
      img {
        width: 3.03 * 100px;
      }
    }
    .content {
      margin-top: 28px;
      img {
        width: 607px;
      }
    }
  }
  .main7 {
    .title {
      margin-top: 84-55px;

      img {
        width: 3.03 * 100px;
      }
    }
    .content {
      margin-top: 68px;
      padding-bottom: 116px;

      img {
        width: 429px;
      }
    }
  }
  .main5 {
    .title {
      img {
        width: 1.8 * 100px;
      }
    }
    .content {
      padding-top: 0.59 * 100px;
      padding-bottom: 0.51 * 100px;
      /*      display: flex;
            flex-wrap: nowrap;
            align-items: stretch;
            flex-direction: row;
            justify-content: space-around;*/
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      text-align: left;
      .hastop {
        margin-top: 0.28 * 100px;
      }
      .product_img {
        width: 432px;
        text-align: right;
        img {
          width: 3.5 * 100px;
        }
      }
      .product_body {
        font-size: 14px;
        color: #474747;
        width: 51%;
        margin-left: 67px;
        max-width: 386px;
        span {
          display: block;
          margin-bottom: 0.32 * 100px;
        }
        p {
          font-size: 12px;
          color: #767676;
          line-height: 20px;
        }
      }
    }
  }
  .indexmain4 {
    position: relative;
    background: url('../images/bj-ying-4.png') left top;
    background-size: 1600px 839px;
    .main4 {
      /*
      position: absolute;
      left: 0;
      top: 0;
      */
      width: 100%;
      .title {
        text-align: center;
        img {
          width: 189px;
        }
      }
      .mainContent {
        padding-bottom: 0.88 * 100px;
        padding-top: 0.36 * 100px;
      }
    }
  }
}

.sharewheelchair {
  .main2 {
    .title {
      img {
        width: 2.411 * 100px;
      }
    }
  }
  .main5 {
    .title {
      img {
        width: 1.185 * 100px;
      }
    }
    .content {
      margin: 49px 0 106px;
    }
    .borderGreyDiv {
      font-size: 14px;
      color: #474747;
      div {
        border: 1px solid #E1E3E3;
        border-radius: 8px;
        width: 320px;
        height: 104px;
        padding: 30px 0;
        margin: 15.5px 26px;
      }
      img {
        margin-bottom: 24px;
        width: 65px;
      }
    }
  }
  .main7 {
    .title {
      img {
        width: 2.32 * 100px;
      }
    }
  }

  .indexmain4 {
    position: relative;
    .title {
      position: absolute;
      left: 0;
      top: 0;
      text-align: center;
      img {
        width: 2.411*100px;
      }
    }
  }
  .indexmain6 {
    position: relative;
    .main6 {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      .title {
        text-align: center;
        img {
          width: 118px;
        }
      }
      .mainContent {
        padding-bottom: 0.88 * 100px;
        padding-top: 0.66 * 100px;
      }

    }
  }
}

.escortbed {
  .main2 {
    .title {
      img {
        width: 2.4725 * 100px;
      }
    }
  }
  .indexmain4 {
    position: relative;
    .title {
      position: absolute;
      left: 0;
      top: 0;
      text-align: center;
      img {
        width: 2.73*100px;
      }
    }
  }
  .main5 {
    .title {
      img {
        width: 1.185 * 100px;
      }
    }
    .content {
      margin: 77px 0 137px;
    }
    .borderGreyDiv {
      font-size: 12px;
      color: #6D6D6D;
      div {
        border: 1px solid #E9EAEE;
        border-radius: 10px;
        width: 201px;
        height: 202px;
        padding: 48px 21px;
        margin: 0px 17px;
      }
      .content_title {
        color: #474747;
        font-size: 20px;
        padding: 28px 0 28px;
      }
      p{
        line-height: 20px;
      }
      img {
        width: 72px;
      }
    }
  }
  .indexmain6 {
    position: relative;
    .main6 {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      .title {
        text-align: center;
        img {
          width: 118px;
        }
      }
      .mainContent {
        padding-bottom: 0.88 * 100px;
        padding-top: 0.66 * 100px;
      }

    }
  }
  .main7 {
    .title {
      img {
        width: 1.18 * 100px;
      }
    }
    .content {
      padding-top: 60px;
    }
    .flexViewC {
      background: #ffffff;
      .flex1 {
        width: 464px;
        p {
          padding: 0 63px;
          color: #818181;
          font-size: 14px;
          margin-top: 41px;
        }
        span {
          padding: 0 63px;
          color: #222222;
          font-size: 20px;
        }
      }
    }
  }
  .main8 {
    .title {
      img {
        width: 1.18 * 100px;
      }
    }
    .content {
      padding-top: 66px;
      padding-bottom: 159px;
    }
    table {
      table-layout: fixed;
      thead {
        td:first-child {
          padding: 0;
          position: relative;
          background: linear-gradient(18deg, transparent 49.5%, #f5f5f5 49.5%, #f5f5f5 50.5%, transparent 50.5%);
          p {
            padding: 0px 6px;
          }
        }
      }
    }
  }
}

.indexmain9 {
  position: relative;
  background: url("../images/bj-chuang-8.png") left bottom no-repeat;
  background-size: 100%;
  background-color: #0E95EF;
  .main9 {
    /*
    position: absolute;
    left: 0;
    top: 0;
    */
    width: 100%;
    .title {
      text-align: center;
      img {
        width: 1.18 * 100px;
      }
    }
    .content {
      width: 925px;
      margin: 0 auto;
      /*
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      */
    }
    .content_body {
      color: #FFFFFF;
      font-size: 12px;
      // width: 50%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: stretch;
      img {
        width: 49px;
        padding-right: 34px;
      }
      .div_title {
        font-size: 18px;
        padding-bottom: 30px;
      }
      .div_content {
        text-align: left;
        width: 50%;
        p {
          width: 276px;
        }
        padding-left: 89px;
        padding-top: 47px;
        padding-bottom: 0px;
        padding-right: 46px;
        // 24
        margin-bottom: 81px;
        margin-top: 0;
        position: relative;
      }
    }
    .content_body:first-child {
      border-bottom: 1px solid rgba(255, 255, 255, 0.49);
      .div_content {
        padding-top: 0px;
        padding-bottom: 81px;
        margin-top: 47px;
        margin-bottom: 0;
      }
      .div_content:first-child:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: 1px;
        height: 100%;
        background: rgba(255, 255, 255, 0.49);
      }
    }
  }
}

.main10 {
  .title {
    img {
      width: 1.18 * 100px;
    }
  }
  .flexViewC {
    padding-top: 47px;
    padding-bottom: 51px;
    .flex1 {
      margin: 0 4px;
    }
  }
}

.blueBjContent {
  img {
    width: 0.47 * 100px;
    height: 0.47 * 100px;
  }
  flex-wrap: wrap;
  text-align: center;
  font-size: 12px;
  color: #666666;
  .div_content {
    background: #ffffff;
    border-radius: 6px;
    text-align: left;
    padding: 0.32 * 100px 0.2 * 100px;
    width: 2.4 * 100px;
    height: 2.16 * 100px;
    margin: 0 43px 11px 0;
    .div_title {
      font-size: 22px;
      padding-top: 26px;
      padding-bottom: 26px;
      color: #222222;
    }
    p {
      line-height: 20px;
    }
  }
  .div_content:nth-child(3n) {
    margin: 0px 0px 11px;
  }
  .div_content:hover {
    background: #1A6EFF;
    color: #ffffff;
    .div_title {
      color: #ffffff;
    }
  }
}

.solution {
  position: absolute;
  left: 0;
  bottom: 111px;
  text-align: center;
  width: 100%;
  img {
    width: 648px;
  }
}